import axios from "axios";

// Post In and get Attende Details
export const postIn = (event_id, invitado_id) => {
  /*
  try {
    return axios.get("/api/eventos/"+event_id+"/in/"+invitado_id);
  } catch (error) {
    console.error(error)
  }
  */
  return axios.get("/api/eventos/" + event_id + "/in2/" + invitado_id);
};

// Post Out and get Attende Details
export const postOut = (event_id, invitado_id) => {

  try {
    return axios.get("/api/eventos/" + event_id + "/out/" + invitado_id);
  } catch (error) {
    console.error(error)
  }
};


//Get Attende Details
export const getAttendeeDetails = (guid) => {
  try {
    return axios.get("/api/attendees/getAttendeeByGuid/" + guid);
  } catch (error) {
    console.error(error)
  }
};

//Get Attende Details
export const updateExternalId = (guid, externalId) => {
  try {
    return axios.post("/api/attendees/updateExternalId/" + guid + "/" + externalId);
  } catch (error) {
    console.error(error)
  }
};


export const logAttendeeBadgePrint = (id, guid) => {
  try {
    const stationId = localStorage.getItem("station");
    const checkpoint = {
      name: 'AttendeeBadgePrint',
      data: {
        id: id,
        guid: guid || '',
        stationId: stationId || ''
      }
    }

    return axios.post('/api/checkpoints', checkpoint);
  }
  catch (error) {
    console.log(error);
  }
}
